import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import './ContactSectionStyles.scss'

import { colorToStr, ColorType } from '../../utils/color'

import ContactForm from '../contactForm/ContactForm'

const ContactSection = ({
  contactSectionTitle,
  contactSectionBackgroundColor,
  contactSectionTextColor
}) => {
  return (
    <section className="contact-section" style={{ backgroundColor: `${colorToStr(contactSectionBackgroundColor.color[0])}`, color: `${colorToStr(contactSectionTextColor.color[0])}` }}>
      <div className="contact-form-container">
        <h2>Contact Us</h2>
        <ContactForm />
      </div>
    </section>
  )
}


ContactSection.propTypes = {
  contactSectionTitle: PropTypes.string.isRequired,
  contactSectionBackgroundColor: ColorType.isRequired,
  contactSectionTextColor: ColorType.isRequired
}

export default () => (
  <StaticQuery
    query={graphql`
      query ContactSectionQuery {
        contentfulHomePage {
          contactSectionTitle
          contactSectionBackgroundColor {
            ...ColorFragment
          }
          contactSectionTextColor {
            ...ColorFragment
          }
        }
      }
    `}
    render={data => (
      <ContactSection {...data.contentfulHomePage} />
    )}
  />
)