import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './SliderStyles.scss'

import { colorToStr, ColorType } from '../../utils/color'

class Slider extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
  }

  componentDidMount() {
    this.setSlidingInterval()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  setSlidingInterval() {
    this.interval = setInterval(() => {
      this.setState({ activeIndex: ((this.state.activeIndex + 1) % this.props.children.length) })
    }, this.props.delay)
  }

  render() {
    const {
      bgColor,
      btnColor,
      children
    } = this.props
    const { activeIndex } = this.state
    let color = colorToStr(btnColor.color[0])
    let hoverStyles = {
      '--hover-bg-color': `${color}`
    }

    return (
      <div className="slider-container" style={{ backgroundColor: bgColor }}>
          <div className="slider" style={{ left: `-${activeIndex * 100}vw`}}>
            {
              children.map((child, index) => {
                let classes = "panel"
                if (index === activeIndex) {
                  classes += " active"
                }
                return (
                  <div key={index} className={classes}>
                    {child}
                  </div>
                )
              })
            }
          </div>
        <div>
          {
            children.map((c, index) => {
              let classes = "slider-button"
              if (index === activeIndex) {
                classes += " active"
              }
              const onClick = () => {
                clearInterval(this.interval)
                this.setState({ activeIndex: index })
                this.setSlidingInterval()
              }
              return (
                <button 
                  key={index}
                  className={classes}
                  onClick={onClick}
                  style={{
                    border: `1px solid ${color}`,
                    ...hoverStyles
                  }}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

Slider.defaultProps = {
  delay: 4000,
  bgColor: 'transparent',
  children: null
}

Slider.propTypes = {
  delay: PropTypes.number,
  bgColor: PropTypes.string,
  btnColor: ColorType.isRequired,
  children: PropTypes.arrayOf(PropTypes.element)
}

export default Slider