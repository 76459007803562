import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import './ServicesSectionStyles.scss'

import { colorToStr, ColorType } from '../../utils/color'

import Service from '../service/Service'

const ServicesSection = ({
  servicesSectionTitle,
  servicesTextColor,
  servicesBackgroundOverlayColor,
  servicesBackgroundOverlayOpacity,
  servicesSectionBackgroundImage,
  edges
}) => {
  let bgImage = servicesSectionBackgroundImage
  let bgImageUrl = "none"
  if (bgImage && bgImage.description !== "dummy") {
    bgImageUrl = `url("${bgImage.file.url}")`
  }
  return (
    <section className="services-section" style={{ backgroundImage: bgImageUrl, color: `${colorToStr(servicesTextColor.color[0])}`  }} >
      <div className="bg-overlay" style={{ backgroundColor: `${colorToStr(servicesBackgroundOverlayColor.color[0])}`, opacity: `${servicesBackgroundOverlayOpacity}`}} />
      <div className="content-wrapper">
        <h2>{ servicesSectionTitle }</h2>
        <div className="service-cards-wrapper">
          {
            edges.map((edge, index) => {
              return (<Service mini id={edge.node.name.replace(/ /g, '-')} key={index} {...edge.node}/>)
            })
          }
        </div>
      </div>
    </section>
  )
}

ServicesSection.defaultProps = {
  servicesSectionTitle: 'Services',
  servicesSectionBackgroundImage: null,
}

ServicesSection.propTypes = {
  servicesSectionTitle: PropTypes.string,
  servicesBackgroundOverlayColor: ColorType.isRequired,
  servicesBackgroundOverlayOpacity: PropTypes.number.isRequired,
  servicesTextColor: ColorType.isRequired,
  servicesSectionBackgroundImage: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ServicesSectionQuery {
        allContentfulService {
          edges {
            node {
              name
              description {
                id
                childMarkdownRemark {
                  htmlAst
                }
              }
              price
              priceDetails
              image {
                description
                file {
                  url
                }
              }
              associatedBackgroundColor {
                ...ColorFragment
              }
              associatedTextColor {
                ...ColorFragment
              }
            }
          }
        }
        contentfulHomePage {
          servicesSectionTitle
          servicesBackgroundOverlayColor {
            ...ColorFragment
          }
          servicesBackgroundOverlayOpacity
          servicesTextColor {
            ...ColorFragment
          }
          servicesSectionBackgroundImage {
            description
            file {
              url
            }
          }
        }
      }
    `}
    render={data => (
      <ServicesSection {...data.allContentfulService} {...data.contentfulHomePage} />
    )}
  />
)