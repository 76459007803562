import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import { buildComponentsFromHtmlAst } from '../../utils/helpers'

import './TestimonialSectionStyles.scss'

import Slider from '../slider/Slider'

import { colorToStr, ColorType } from '../../utils/color'

const TestimonialSection = ({
  testimonialsTitle,
  testimonialsBackgroundColor,
  testimonialsTextColor,
  testimonials
}) => {
  return (
    <section id="testimonials" className="testimonials-container" style={{ backgroundColor: `${colorToStr(testimonialsBackgroundColor.color[0])}`, color: `${colorToStr(testimonialsTextColor.color[0])}` }}>
      <h2>{ testimonialsTitle }</h2>
      <Slider btnColor={testimonialsTextColor}>
        {
          testimonials &&
          testimonials.map((t, index) => {
            return (
              <div className="testimonial" key={index}>
                { buildComponentsFromHtmlAst(t.quote.childMarkdownRemark.htmlAst.children) }
                <div className="name">
                  { `– ${t.name}` }
                </div>
              </div>
            )
          })
        }
      </Slider>
    </section>
  )
}

TestimonialSection.defaultProps = {
  testimonials: null
}

TestimonialSection.propTypes = {
  testimonialsTitle: PropTypes.string.isRequired,
  testimonialsBackgroundColor: ColorType.isRequired,
  testimonialsTextColor: ColorType.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    quote: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        htmlAst: PropTypes.shape({
          children: PropTypes.array
        })
      })
    })
  }))
}

export default () => (
  <StaticQuery
    query={graphql`
      query TestimonialsQuery {
        contentfulHomePage {
          testimonialsTitle
          testimonialsBackgroundColor {
            ...ColorFragment
          }
          testimonialsTextColor {
            ...ColorFragment
          }
          testimonials {
            name
            quote {
              childMarkdownRemark {
                htmlAst
              }
            }
          }
        }
      }
    `}
    render={data => (
      <TestimonialSection {...data.contentfulHomePage} />
    )}
  />
)