import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import { buildComponentsFromHtmlAst } from '../../utils/helpers'

import { colorToStr, ColorType } from '../../utils/color'

import './HomeAboutSectionStyles.scss'

const HomeAboutSection = ({
  aboutCreatorTitle,
  aboutCreatorContent,
  creatorPortrait,
  aboutCreatorBackgroundColor,
  aboutCreatorTextColor,
  aboutSectionTitle,
  aboutSectionContent,
  aboutSectionBackgroundColor,
  aboutSectionTextColor
}) => {
  return (
    <section id="about-section" className="about-section" style={{ backgroundColor: `${colorToStr(aboutSectionBackgroundColor.color[0])}`, color: `${colorToStr(aboutSectionTextColor.color[0])}` }}>
      <div className="content-wrapper">
        <section className="about-main">
            <div className="content-wrapper">
              <h2>{ aboutSectionTitle }</h2>
              { aboutSectionContent && buildComponentsFromHtmlAst(aboutSectionContent.childMarkdownRemark.htmlAst.children) }
            </div>
        </section>
        <section className="about-author" style={{ backgroundColor: `${colorToStr(aboutCreatorBackgroundColor.color[0])}`, color: `${colorToStr(aboutCreatorTextColor.color[0])}` }}>
          <div className="content-wrapper">
            <h2>{ aboutCreatorTitle }</h2>
            <div className="image-and-text">
              { creatorPortrait && 
                  <img src={creatorPortrait.file.url} alt={creatorPortrait.description} />
              }
              { aboutCreatorContent && buildComponentsFromHtmlAst(aboutCreatorContent.childMarkdownRemark.htmlAst.children) }
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

HomeAboutSection.defaultProps = {
  smallWidthScreen: true,
  creatorPortrait: null,
  aboutCreatorBackgroundColor: 'BFB5AF',
  aboutCreatorContent: null,
  aboutSectionContent: null,
}

HomeAboutSection.propTypes = {
  smallWidthScreen: PropTypes.bool,
  aboutCreatorTitle: PropTypes.string.isRequired,
  aboutCreatorContent: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      htmlAst: PropTypes.shape({
        children: PropTypes.array
      })
    })
  }),
  creatorPortrait: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  aboutCreatorBackgroundColor: ColorType,
  aboutCreatorTextColor: ColorType,
  aboutSectionTitle: PropTypes.string.isRequired,
  aboutSectionContent: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      htmlAst: PropTypes.shape({
        children: PropTypes.array
      })
    })
  }),
  aboutSectionBackgroundColor: ColorType,
  aboutSectionTextColor: ColorType
}

export default () => (
  <StaticQuery
    query={graphql`
      query HomeAboutSectionQuery {
        contentfulHomePage {
          aboutCreatorTitle
          aboutCreatorBackgroundColor {
            ...ColorFragment
          }
          aboutCreatorTextColor {
            ...ColorFragment
          }
          aboutCreatorContent {
            childMarkdownRemark {
              htmlAst
            }
          }
          creatorPortrait {
            description
            file {
              url
            }
          }
          aboutSectionTitle
          aboutSectionContent {
            childMarkdownRemark {
              htmlAst
              html
            }
          }
          aboutSectionBackgroundColor {
            ...ColorFragment
          }
          aboutSectionTextColor {
            ...ColorFragment
          }
        }
      }
    `}
    render={data => (
      <HomeAboutSection {...data.contentfulHomePage} />
    )}
  />
)