import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import './LandingScreenStyles.scss'

import { colorToStr, ColorType } from '../../utils/color'

// saved potential bg overlay colors
// #998888 a little purplish
// 5E4F4D umber
// 5E4946 rose ebony
// 443730 old burgundy, best so far

class LandingScreen extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentQuestion: 0,
    }
  }

  componentDidMount() {
    this.questionSliderInterval = setInterval(() => {
      this.setState({ currentQuestion: ((this.state.currentQuestion + 1) % this.props.landingQuestions.length) })
    }, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.questionSliderInterval)
  }

  render() {
    const {
      subtitle,
      landingQuestions,
      landingBackgroundOverlayOpacity,
      landingBackgroundOverlayColor,
      landingTextColor,
      landingMainImage,
      landingBackgroundImage
    } = this.props
    let bgImage = landingBackgroundImage
    let bgImageUrl = "none"
    if (bgImage && bgImage.description !== "dummy") {
      bgImageUrl = `url("${bgImage.file.url}")`
    }

    let landingBgColorStr = colorToStr(landingBackgroundOverlayColor.color[0])
    let landingTextColorStr = colorToStr(landingTextColor.color[0])

    return (
      <div className="landing-screen-container" style={{ backgroundImage: bgImageUrl, color: `${landingTextColorStr}` }}>
        <div className="bg-overlay" style={{ backgroundColor: `${landingBgColorStr}`, opacity: `${landingBackgroundOverlayOpacity}`}} />
        <div className="content">
          <div className="questions">
            {
              landingQuestions.map((q, index) => {
                let classes = "q-wrapper"
                if (index === this.state.currentQuestion) {
                  classes += " active"
                } 
                if (index === ((this.state.currentQuestion + 1) % this.props.landingQuestions.length) ) {
                  classes += " next"
                } 
                return (
                  <div key={index} className={classes}>
                    <h1>{q.question}</h1>
                  </div>
                )
              })
            }
          </div>
          <p>{subtitle}</p>
          { landingMainImage &&
            <img src={landingMainImage.file.url} alt={landingMainImage.description}/>
          }
        </div>
      </div>
    )
  }
}

LandingScreen.defaultProps = {
  landingBackgroundImage: null
}

LandingScreen.propTypes = {
  subtitle: PropTypes.string.isRequired,
  landingQuestions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string
  })).isRequired,
  landingBackgroundOverlayOpacity: PropTypes.number.isRequired,
  landingBackgroundOverlayColor: ColorType,
  landingTextColor: ColorType,
  landingMainImage: PropTypes.shape({
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }).isRequired,
  landingBackgroundImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query LandingScreenQuery {
        contentfulHomePage {
          subtitle
          landingTextColor {
            ...ColorFragment
          }
          landingBackgroundOverlayColor {
            ...ColorFragment
          }
          landingBackgroundOverlayOpacity,
          landingMainImage {
            description
            file {
              url
            }
          }
          landingBackgroundImage {
            description
            file {
              url
            }
          }
          landingQuestions {
            question
          }
        }
      }
    `}
    render={data => (<LandingScreen {...data.contentfulHomePage} />)}
  />
)
