import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import HomeAboutSection from '../components/homeAboutSection/HomeAboutSection'
import ContactSection from '../components/contactSection/ContactSection'
import Layout from '../components/layout/Layout'
import LandingScreen from '../components/landingScreen/LandingScreen'
import SEO, { SeoType } from '../components/seo'
import TestimonialSection from '../components/testimonialSection/TestimonialSection'
import ServicesSection from '../components/servicesSection/ServicesSection'

const IndexPage = ({ data }) => { 
  return (
    <Layout navbarData={ data.contentfulHomePage.navbar } landingPage>
      <LandingScreen />
      <SEO { ...data.contentfulHomePage.seo } />
      <HomeAboutSection />
      <ServicesSection />
      <TestimonialSection />
      <ContactSection />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    contentfulHomePage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      seo: SeoType.isRequired
    })
  }).isRequired
}

export const query = graphql`
  query HomePageQuery {
    contentfulHomePage {
      title
      navbar {
        ...NavbarFragment
      }
      seo {
        ...SeoFragment
      }
    }
  }
`

export default IndexPage
